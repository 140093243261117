.red {
    fill: #ff2400;
}
.animation {
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.partAnim {
    animation-name: part1;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.bigPartAnim {
    animation-name: bigPart;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

#l1 {
    transform-origin: 98px 140px 0px;
    animation-name: part1;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
#r2 {
    transform-origin: 218px 140px 0px;
    animation-name: part1;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.part2 {
    animation-name: part2;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
#left {
    transform-origin: 118px 140px 0px;
    animation-name: bigPart;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
#right {
    transform-origin: 178px 140px 0px;
    animation-name: bigPart;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
#logo {
    animation-name: logo;
    transform-origin: center center;
    animation-duration: 2.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
@keyframes part1 {
    0% {
        transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg);
        fill: #ff2400;
    }
    25% {
        fill: #b21900;
    }
    50% {
        transform: rotateX(180deg) rotateZ(90deg);
        fill: #ff2400;
    }
    75% {
        fill: #b21900;
    }
    100% {
        transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg);
        fill: #ff2400;
    }
}
@keyframes part2 {
    0% {
        fill: #ff2400;
    }
    12% {
        fill: #e52000;
    }
    25% {
        fill: #ff2400;
    }
    75% {
        fill: #ff2400;
    }
    87% {
        fill: #e52000;
    }
    100% {
        fill: #ff2400;
    }
}
@keyframes bigPart {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(-180deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}
@keyframes logo {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}